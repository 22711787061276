<template>
  <xlsx-workbook>
    <xlsx-sheet
      v-for="sheet in worksheets"
      :key="sheet.name"
      :collection="sheet.data"
      :sheet-name="sheet.name"
    />

    <xlsx-download
      :filename="`${exportName}.xlsx`"
    >
      <button>
        {{ item.text }}
      </button>
    </xlsx-download>
  </xlsx-workbook>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import _ from 'underscore'

import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'

import dates from 'src/utils/dates'
import { fireanalytics } from 'src/store/firebase'

const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'ExportXlsx',
  components: {
    XlsxDownload,
    XlsxSheet,
    XlsxWorkbook,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    showExportBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeMap: {
        boolean: {
          type: 'b',
        },
        capitalize: {
          type: 's',
        },
        currency: {
          type: 'n',
          format: '$#,##0.00',
        },
        dollars: {
          type: 'n',
          format: '$#,##0.00',
        },
        date: {
          type: 'd',
          format: 'mm/dd/yyyy',
        },
        dateObject: {
          type: 'd',
          format: 'mm/dd/yyyy',
        },
        error: {
          type: 'e',
        },
        hhmm: {
          type: 's',
        },
        join: {
          type: 's',
        },
        number: {
          type: 'n',
          format: '#,###,0',
        },
        percentage: {
          type: 'n',
        },
        discrepancyPercentage: {
          type: 'n',
        },
        text: {
          type: 's',
        },
        stub: {
          type: 'z',
        },
      },
    }
  },
  computed: {
    ...mapToolsGetters([
      'columnsByKeyReducer',
      'currentColumns',
      'currentGroups',
      'reportKey',
      'sortByDirections',
      'sortByKeys',
    ]),
    ...mapToolsState({
      reportName: (state) => state.reportName,
      tableSortMap: (state) => state.tableSortMap,
    }),
    exportData() {
      const {
        columnsByKeyReducer,
        currentColumns: exportColumns,
        currentGroups: exportGroups,
        reportName: exportName,
        showExportBar,
        typeMap,
      } = this

      if (!showExportBar || !exportColumns) return { headers: [], rows: [] }

      const columnsByKey = columnsByKeyReducer(exportColumns)
      const sortedItemsByGroup = exportGroups.reduce((previousValue, currentGroup) => {
        const { items: currentItems, rows: currentRows } = currentGroup
        const unsortedItems = currentItems || currentRows
        const promiseSortedRows = this.sortGroupedItemsByAllColumns({ columnsByKey, unsortedItems })

        return previousValue.concat(promiseSortedRows)
      }, [])
      const reportItems = sortedItemsByGroup.reduce((flatArray, items) => flatArray.concat(items), [])

      const headers = exportColumns.map((column) => column.columnName)
      const getExportAs = (exportAs) => (Array.isArray(exportAs) && exportAs[0]) || exportAs

      const getFormatForFunction = (formatter) => typeof formatter === 'function' && 'text'
      const getFormatForArray = (formatter) => Array.isArray(formatter) && formatter[0]
      const getFormatterType = (formatter) => getFormatForFunction(formatter) || getFormatForArray(formatter) || formatter

      const formatCells = (exportRows, item) => {
        const exportRow = exportColumns.reduce((newItem, column) => {
          const { columnName, exportAs, formatAs } = column
          const formatter = getExportAs(exportAs) || formatAs
          const formatterType = getFormatterType(formatter) || 'text'
          const cellMap = typeMap[formatterType] || typeMap.text
          const { type: cellType, format: cellFormat } = cellMap
          const isNumerical = cellType === 'n'
          const numberFormat = isNumerical && cellFormat ? cellFormat : undefined

          const formattedValue = column.columnValue(item)
          const exportValue = isNumerical ? formattedValue.replace(/(\$|%|,)/g, '') : formattedValue

          newItem[columnName] = { t: cellType, v: exportValue, z: numberFormat }

          return newItem
        }, {})

        exportRows.push(exportRow)

        return exportRows
      }

      const valueGetter = (row) => dates.get(row.date).unix()
      const sortCreativesByDate = () => /creatives_/.test(exportName) && _.sortDscByCaseInsensitive(reportItems, valueGetter)
      const sourceRows = sortCreativesByDate() || reportItems
      const rows = sourceRows.reduce(formatCells, [])

      return { headers, rows }
    },
    exportName() {
      const { reportName } = this
      const exportDate = dates.get().format('YYYY-MM-DD')

      return `oao_${reportName}_on_${exportDate}`
    },
    worksheets() {
      const { exportData, reportName } = this
      const { rows: exportRows } = exportData

      return [
        {
          name: reportName,
          data: exportRows,
        },
      ]
    },
  },
  watch: {
    worksheets() {
      fireanalytics.event('goal_preemption', {
        category: 'Export',
        description: 'Export As XLSX',
        label: `Export As XLSX Generated for Report "${this.reportName}"`,
      })
    },
  },
  methods: {
    sortGroupedItems({ columnKey, columnDirection, columnFormat, items }) {
      const sortDsc = columnDirection === this.tableSortMap.DSC
      const sorter = sortDsc ? _.sortDscByCaseInsensitive : _.sortAscByCaseInsensitive
      const isDateColumn = columnFormat === 'date'

      const valueGetter = (item) => {
        const getValue = () => {
          const value = !isDateColumn && item.normalized ? item.normalized[columnKey] : item[columnKey]
          const sortArrayValues = () => (sortDsc ? value.sort().reverse() : value.sort())
          return Array.isArray(value) ? sortArrayValues()[0] : value?.value || value
        }
        const getDate = (value) => dates.getUnixDate(value)
        return isDateColumn ? getDate(getValue()) : getValue()
      }

      return sorter(items, valueGetter)
    },
    sortGroupedItemsByAllColumns({ columnsByKey, unsortedItems }) {
      const sortItems = (items, columnKey, index) => {
        const columnDirection = this.sortByDirections[index]
        const columnFormat = columnsByKey[columnKey]?.formatAs

        return this.sortGroupedItems({ columnDirection, columnFormat, columnKey, items })
      }

      return this.sortByKeys.reduceRight(sortItems, unsortedItems)
    },
  },
}
</script>
